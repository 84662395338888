<script setup lang="ts">
import { useOverbooking } from '~/hooks/useOverbooking'

const props = defineProps({
	currentOccupancy: { type: Number, required: true },
})
const { currentOccupancy } = toRefs(props)

const { bookingPercentage, isOverbooked, overbookingPercentage } = useOverbooking(currentOccupancy)
</script>

<template>
	<div class="flex h-full w-3 flex-col-reverse truncate rounded bg-white">
		<div
			class="max-h-full w-3 bg-warm-grey-600"
			:class="isOverbooked ? 'rounded-bl rounded-br' : 'rounded'"
			:style="`height: ${bookingPercentage}%;`"
		/>
		<div class="max-h-full w-3 rounded-tl rounded-tr bg-warning-400" :style="`height: ${overbookingPercentage}%;`" />
	</div>
</template>
