<script lang="ts" setup>
import { SpSvg } from '~~/src/autogen/SpSvg'
import { IconSizes } from '~~/src/constants/iconSizes'
import { utilNumber } from '~~/src/utils/UtilNumber'

const props = defineProps({
	firstNumber: { type: Number, required: true },
	secondNumber: { type: Number, required: true },
})
const { firstNumber, secondNumber } = toRefs(props)

const percentage = computed(() => utilNumber.comparisonPercentage(firstNumber.value, secondNumber.value))

const textColor = computed(() => (percentage.value < 0 ? 'text-error-900' : 'text-success-900'))
const fillColor = computed(() => (percentage.value < 0 ? 'fill-error-900' : 'fill-success-900'))
const backgroundColor = computed(() =>
	percentage.value < 0 ? 'bg-error-200 border-error-400' : 'bg-success-200 border-success-400'
)
const icon = computed(() => (percentage.value < 0 ? SpSvg.BasicArrowDown : SpSvg.BasicArrowTop))
const text = computed(() =>
	percentage.value > 0
		? `+ ${utilNumber.toPercentage(percentage.value / 100)}`
		: utilNumber.toPercentage(percentage.value / 100)
)
const showText = computed(() => firstNumber.value > 0)
const iconClasses = computed(() => (showText.value ? '' : 'mx-2'))
</script>

<template>
	<div
		v-if="firstNumber != 0 && percentage != 0"
		class="flex h-5 min-w-[32px] items-center gap-1 rounded border px-1"
		:class="backgroundColor"
	>
		<span class="text-xs" :class="textColor" v-if="showText">{{ text }}</span>
		<CommonIcon :fill="fillColor" :icon-name="icon" :icon-size="IconSizes.XS" :class="iconClasses" />
	</div>
</template>
