<script setup lang="ts">
import { SpCommonIcon, SpIconSize } from '@dev.smartpricing/sp-vue-components'
import { TranslationKeys } from '~/i18n/TranslationKeys'

const props = defineProps<{
	isOrphanNightFixed: boolean | undefined
}>()
</script>

<template>
	<div
		v-if="props.isOrphanNightFixed"
		class="mb-3 mt-2 flex shrink-0 items-center gap-2 self-stretch rounded-md bg-warm-grey-100 p-4"
	>
		<SpIcon :name="SpCommonIcon.HalfMoonEmpty" :size="SpIconSize.Small" />
		<SpText
			:text="useLocale().translate(TranslationKeys.ORPHAN_NIGHTS_CALENDAR_PRICE_DETAIL)"
			:size="SpTextSize.BodyRegular01"
		/>
	</div>
</template>
